import React from 'react';
import { ContainerLogin } from '@components';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import { WrapperLogin } from './styled';
import Viewport from 'src/shared/hooks/viewport';
import { graphql, useStaticQuery } from 'gatsby';
import { getMetadataPage } from '@utils/metadata';

const Login = () => {
    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Iniciar sesión',
            pathname: 'iniciar-sesion/'
        }
    ];

    const queryPageIniciarSesion = useStaticQuery(graphql`
        query pageIniciarSesion {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "IniciarSesion" } }) {
                nodes {
                    title
                    slug
                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        nodes: [page]
    } = queryPageIniciarSesion.allContentfulPaginaInternaFooter;

    return (
        <Layout>
            <SEO {...getMetadataPage(page.metaData, page.slug)} index="noindex, nofollow" />
            <WrapperLogin>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}

                <ContainerLogin page={page}></ContainerLogin>
            </WrapperLogin>
        </Layout>
    );
};

export default Login;
